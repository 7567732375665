.container {
  background: #fafafa;
  /* min-height: 100vh; */

  /* box-shadow: inset 0px -2px 5px -1px rgba(0, 0, 0, 0.13), */
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 24px 20px 16px;
  background: #ffffff;
}

.heading {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
.bName {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
}

.deadline {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  padding: 0 24px;
}

.requirementHeading {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #09121f;
}

.subSection {
  background: #ffffff;
}

.mentionChip {
  padding: 4px 12px;
  background: rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 100px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #09121f;
}

.subTileHeading {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  position: relative;
  color: #000000;
}
.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
}

.subTileHeading::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background: #2130ff;
  border-radius: 999px;
  left: -14.5px;
  top: 6px;
  /* border: 2px solid #2130FF; */
}

.lastSubTileHeading {
  font-style: italic;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #999999;
  position: relative;
}

.subTileDescription {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;

  color: #999999;
}

.lastSubTileHeading::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background: #eeeeee;
  border-radius: 999px;
  left: -14.5px;
  top: 10px;
  /* border: 2px solid #2130FF; */
}
.seeMore {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}

.drawerHeading {
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
}

.playerWrapper {
  padding: 0 20px;
}

.reqNotSentHeading {
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
}

.reqNotSentDesc {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.video {
  width: 242px;
  height: 242px;
}
.image {
  width: 242px;
  /* height: 384px; */
  display: flex;
  flex-direction: column;
}

.draftImage {
  object-fit: cover;
  width: 242px;
  height: 242px;
}

.changesContainer {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  padding: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #999999;
  margin-top: 6px;
}

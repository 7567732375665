.container {
  border: 1px solid #eeeeee !important;
  border-radius: 8px !important;
}

.cName {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #09121f;
}

.bName {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
}

.progress {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 3px !important;
  color: #999999;
}

.description {
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #09121f;
  margin-bottom: 12px !important;
}

.container {
  display: grid;
  justify-content: center;
  margin-top: 53px;
}

.amount {
  font-weight: 500;
  font-size: 40px;
  line-height: 56px;
  text-align: center;
  letter-spacing: 0.02em;
}

.accountName {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #09121f;
}

.account {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #999999;
}

.transactionId {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #999999;
}

.plainButton {
  padding: 8px 16px;
  border: 1px solid #1677ff;
  border-radius: 1000px;
  font-size: 15px;
  line-height: 21px;
  color: #1677ff;
  width: max-content;
  margin: 0 auto;
  margin-top: 65px;
}

.date {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #09121f;
}

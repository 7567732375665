.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.header {
  padding: 0px 24px;
  display: flex;
  gap: 12px;
}

.profilePic {
  width: 64px;
  height: 64px;
  border-radius: 999px;
  object-fit: cover;
}

.headingDetails {
  display: flex;
  flex-direction: column;
  padding: 2px 0;
  gap: 3px;
}

.name {
  font-weight: 600;
  font-size: 20px;
}

.niche {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.button {
  padding: 0px 24px;
  margin-top: 29px;
}

.options {
  display: flex;
  margin-left: 1em;
  margin-right: 1em;
  flex-direction: column;
}
.option {
  display: flex;
  justify-content: space-between;
  padding: 20px 21.5px 20px 24px;
  border-bottom: 1px solid #eeeeee;
}

.option > p {
  font-weight: 400;
  font-size: 16px;
}
.logout {
  display: flex;
  justify-content: space-between;
  padding: 20px 21.5px 20px 24px;
  text-decoration: underline;

  font-weight: 400;
  font-size: 16px;

  cursor: pointer;
}

.profilePicContainer {
  display: flex;
  gap: 12px;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 47px 24px 0 24px;
  gap: 21px;
}

.label {
  font-weight: 400;
  font-size: 12px;
  color: #999999;
}

.desc {
  font-weight: 500;
  font-size: 16px;
  color: #09121f;
}

.options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
  padding: 12px;
}

.options > p {
  font-weight: 400;
  font-size: 17px;
}

.email {
  display: flex;
  flex-direction: column;
}

.email > div {
  align-self: flex-end;
}

.profilePicContainer {
  margin: 0 auto;
  display: grid;
  gap: 8px;
}

.profilePic {
  width: 96px;
  height: 96px;
  border-radius: 999px;
  object-fit: cover;
}

.uploadLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  width: 20px;
  height: 20px;
}

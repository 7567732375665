.container {
  padding: 0 12px;
  max-width: 100vw;
  margin-top: 1em;
}

.heading {
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  padding: 0 11px;
  margin: 45px 0 22px !important;
}

.customSkeleton {
  --width: 90vw;
  --height: 100px;
  --border-radius: 8px;
  margin: 20px auto;
}

.container {
  border-radius: 12px;
  border: 2px solid #d9d9d9;
  /* padding: 20px 22px; */
}

.active {
  border: 2px solid #3377ff;
}

.disabled {
  opacity: 0.3;
  min-height: 110px;
}

.message {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 8px 12px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.activeHeader {
  background: #f6f6f6;
  border-radius: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.video {
  width: 242px;
  height: 242px;
}

.image {
  width: 242px;
  /* height: 384px; */
  display: flex;
  flex-direction: column;
}

.draftImage {
  object-fit: cover;
  width: 242px;
  height: 242px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.35);
  border-radius: 20px;
}

.chatTile {
  display: flex;
  padding: 20px;
  justify-content: space-between;
}
.chatTile:hover {
  background-color: #fafafa;
  cursor: pointer;
}

.name {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.message {
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
}

.profile {
  border-radius: 100px;
  width: 48px;
  height: 48px;
  object-fit: cover;
}

.time {
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  text-align: right;
  color: #2130ff;
}

.unread {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 23px;
  min-height: 20px;
  background: #2130ff;
  border-radius: 24px;
  color: #ffffff;
}

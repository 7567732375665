.container {
  display: flex;
  flex-direction: column;

  margin-top: 55px;
}

.header {
  font-weight: 400;
  font-size: 18px;
  color: #09121f;
}

.subHeading {
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  margin-top: 14px;
}

.progressBar {
  margin-top: 18px;

  display: flex;
  align-items: center;
  gap: 2px;
}

.barActive {
  width: 75px;
  height: 11px;
  background: linear-gradient(93.4deg, #215fff 0%, #0044f4 100%);
  position: relative;
}

.activeIcon {
  position: absolute;
  right: -14px;
  bottom: -8px;
  z-index: 10;
}
.bar {
  width: 75px;
  height: 11px;
  position: relative;
  background: #cccccc;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 14px 12px;
  gap: 12px;

  background: #ffffff;
  margin-top: 34px;

  border: 1px solid #cccccc;
  border-radius: 8px;
}

.cardHeader {
  display: flex;

  align-items: center;
  padding: 0px;
  gap: 9px;
}

.cardHeader > p {
  font-weight: 500;
  font-size: 14px;

  color: #09121f;
}

.desc {
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  margin-bottom: 18px;
}

.kycHeader {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #09121f;
}

.kycDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
}

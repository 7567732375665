.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0px;
  gap: 12px;
  background: #050a30;
  border-radius: 8px;
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.description {
  color: #999999;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 0 16px;
}

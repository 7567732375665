.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    background: #FFFFFF;
    border: 0.5px solid #D9D9D9;
    box-shadow: 0px 0px 8px rgba(10, 6, 18, 0.04);
    border-radius: 8px;
    justify-content: center;
    padding: 48px;
}

.label{
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}

.value{
    font-weight: 700;
    font-size: 40px;
    line-height: 47px; 
}

@media screen and (max-width: 1000px) {
    .container{
        padding: 0px;
        height: 115px;
    }
    
    .label{
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: rgba(0, 0, 0, 0.45);
    }
    
    .value{
        font-size: 20px;
        line-height: 23px; 
    }

}
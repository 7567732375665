.container {
  padding: 15px 0px;
  cursor: pointer;
  // background-color: #2130ff10;

  padding-right: 15px;
}

.innerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.innerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: contain;
  // border: 1px solid rgb(230, 230, 230);
}

.text {
  font-size: 14px;
}

.timeText {
  display: block;
  color: #999999;
  font-size: 12px;
  margin-left: 75px;
}

.newNotificationDot {
  background-color: #2130ffca;
  border-radius: 10px;
  height: 7px;
  width: 7px;
  margin: 0px 10px;
}

@primary-color: #2130FF;@--z-index: 1300;
.recievedMain {
  align-self: flex-end;
  background-color: #2130ff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 1em 0.5em 0.5em 0.5em;
  min-height: 3em;
  min-width: 20%;
  max-width: 70%;
  border-radius: 8px 8px 2px 8px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
}

.sentMain {
  align-self: flex-start;
  background-color: #ffffff;
  border-radius: 8px 8px 8px 2px;
  padding: 1em 0.5em 0.5em 0.5em;
  min-height: 3em;
  min-width: 20%;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.iconMe {
  font-size: 16px;
  color: #cccccc;
}
.iconOther {
  font-size: 16px;
  color: #cccccc;
}

.timeMe {
  font-size: 10px;
  color: #cccccc;
}

.timeOther {
  font-size: 12px;
  color: #cccccc;
}

.recievedMsg {
  font-size: 15px;
  color: #ffffff;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.sentMsg {
  font-size: 15px;
  color: #000000;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.bottomMe {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.5em;
}

.bottomOther {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5em;
}

.dateContainer {
  padding: 5px 12px 6px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
  background-color: hsla(0, 0%, 100%, 0.8);
  border-radius: 7.5px;
  box-shadow: 2px 2px 2px rgba(11, 20, 26, 0.13);
  color: rgb(26, 0, 0);
  width: fit-content;
  margin: 1em 0 !important;
  align-self: center;
}

.deletedMessage {
  background-color: rgb(178, 176, 176);
  font-style: italic;
  color: white;
  display: flex;
  align-items: center;
  padding: 0 6px;
  min-height: 50px;
}

.campaignHeader {
  padding: 17px 13px 17px 26px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
}

.cName {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #09121f;
}

.bName {
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}

.tileHeading {
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
}

.cDetails {
  padding: 28px 20px 20px 24px;
  background: #ffffff;
}

.productList {
  padding: 28px 20px 20px 24px;
  background: #ffffff;
}

.deliverables {
  padding: 28px 20px 20px 24px;
  background: #ffffff;
}

.deliverableHeading {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
}

.delDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.45);
}

.bGuidelines {
  padding: 28px 20px 20px 24px;
  background: #ffffff;
}

.commercials {
  /* display: flex;
  justify-content: space-between; */
  padding: 28px 20px 20px 24px;
  background: #ffffff;
}

.commercialCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px;
  gap: 5px;
  background: #eff6fb;
  border: 1px solid #e9ebf3;
  border-radius: 12px;
}

.cardHeading {
  font-style: italic;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #09121f;
}
.cardDesc {
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #09121f;
  opacity: 0.8;
}

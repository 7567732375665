.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%;
}
.posts {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;

  margin: 3px 3px;
}

.noStory {
  height: 100%;
}
.content {
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  gap: 10px;
}
.content > :nth-child(2) {
  font-weight: 500;
  font-size: 18px;

  text-align: center;

  color: #333333;
}
.content > :nth-child(3) {
  text-align: center;
  font-weight: 400;
  font-size: 13px;

  text-align: center;

  color: #999999;
}
.instagramButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15%;
}
.openInstagramButton {
  width: 100% !important;
  margin: 12.5px 24px;
}

.button {
  width: 90%;
  margin: 7px auto;
  /* height: 60px; */

  /* align-self: center; */
}
.buttonContainer {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  margin: 0 -20px;
  background: white;
  padding: 40px 0;
}

.post {
  width: 100%;
  height: 109.85px;
  object-fit: cover;
}
.story {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.overlay {
  position: absolute;
  height: 109.85px;
  width: 100%;
  top: 0;
  background-color: #111111;
  opacity: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: 40px;
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

.scroller {
  height: 100%;
  overflow-y: hidden;
  margin-bottom: 100px;
}

.youtubePosts {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}

.youtubeVideo {
  display: flex;
  gap: 16px;
  height: 90px;
}
.imageContainer {
  position: relative;
}

.selectedVideo {
  position: absolute;
  top: -8px;

  left: -8px;
}

.selected {
  position: absolute;
  top: -5px;

  right: -5px;
}
.youtubeImage {
  width: 161px;
  flex-shrink: 0;
  display: flex;
  height: 90px;
  object-fit: cover;
}
.detail {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 8px 0px;
}

.detail > p:nth-child(1) {
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 125%;
  /* height: 58px; */
  width: 100%;
}

.detail > p:nth-child(2) {
  font-weight: 400;
  font-size: 12px;

  color: #999999;
}

.heading {
  font-size: 20px;

  font-weight: 600;
  line-height: 34px;
  color: #09121f;
}

.manageHeading {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #999999;
}

.walletText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
}

.walletPriceWrapper {
  font-weight: 600;
  font-size: 44px;
  line-height: 62px;
  letter-spacing: 0.02em;
  color: #09121f;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  justify-content: center;
}

.earningBanner {
  padding: 20px 16px;
  justify-content: space-between;
  align-items: flex-end;
  background: linear-gradient(93.4deg, #215fff 0%, #0044f4 100%);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.13);
  border-radius: 8px;
  display: flex;
}

.manageEarning {
  font-size: 12px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #ffffff;
}

.transactionHeading {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.view {
  font-size: 12px;
  line-height: 17px;
  text-align: right;
  text-decoration-line: underline;
}

.customSkeleton {
  --width: 80%;
  --height: 250px;
  --border-radius: 8px;
  margin: 0 auto;
  margin-bottom: 50px;
}

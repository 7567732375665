.card{
    padding: 8px 12px; 
    background: #FFFFFF;
    border: 0.5px solid #D9D9D9;
    box-shadow: 0px 0px 8px rgba(10, 6, 18, 0.04);
    border-radius: 8px;
    height: 400px;
}

.title{
    font-weight: 800;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 16px;
}

.label{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #8C8C8C;
}

.category{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #8C8C8C;
}

.percent{
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
}
.linePercent{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #8C8C8C;

}
.container {
  padding: 0 24px;
}

.amountTile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.billTile {
  padding: 0 20px;
}

.amountDetail {
  font-size: 14px;
  line-height: 20px;
  color: #999999;
}

.amountPrice {
  font-size: 14px;
  line-height: 20px;
  color: #09121f;
  text-align: right;
}

.total {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #09121f;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 16px;
}

.transCard {
  padding: 21px 13px;
  gap: 27px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 9px;
  display: grid;
}

.label {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

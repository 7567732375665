.heading {
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: #09121f;
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #09121f;
}

.desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #999999;
}

.subHeading {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}

.subContent {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.icon {
  width: 24px;
  height: 24px;
}

.container {
  margin-bottom: 50px;
  width: 100%;
}

.header__row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header__logo {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin-right: 10px;
  border: 1px solid rgb(234, 234, 234);
}

.header__text {
  font-size: 18px;
}

.header__timeText {
  display: block;
  color: #999999;
  font-size: 14px;
  margin-left: 50px;
}

.question {
  font-weight: 600;
  margin-bottom: 10px;
  display: block;
}

.answer {
  color: #999999;
}

.answerList {
  padding-left: 20px;
}

.infoItem {
  margin-bottom: 15px;
}

.infoItemHeading {
  font-weight: 600;
  // margin-bottom: 5px;
  display: block;
}

.infoItemText {
  color: #999999;
}

.btnContainer {
  padding: 10px 30px;
  border-top: 1px solid rgb(220, 220, 220);
  position: absolute;
  background-color: #fff;
  width: 100%;
  bottom: 0;
  left: 0;
}

@primary-color: #2130FF;@--z-index: 1300;
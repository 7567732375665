.container {
  width: 100%;
  border-radius: 8px !important;
}

.banner {
  height: 157px;
  object-fit: cover;
  width: 100%;
  border-radius: 8px 8px 0 0;
}

.chip {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1px 6px 2px;
  gap: 4px;
  isolation: isolate;
  background: #316bff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #ffffff;
  position: absolute;
  /* z-index: 1; */
  bottom: 16px;
  left: -5px;
}

.cName {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  color: #09121f;
}

.bName {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #999999;
}

.cPrice {
  font-family: "Roboto";
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #09121f;
}

.paymentChip {
  padding: 8px 12px;
  background: rgb(245, 0, 87);
  opacity: 0.85;
  border-radius: 8px 0 0 8px;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 0.92;
  width: max-content;
  color: white;
  position: absolute;
  right: -12px;
  bottom: 0rem;
}

.banner {
  height: 200px;
  width: 100%;
  object-fit: cover;
  opacity: 0.95;
  /* background: #; */
}

.header {
  background: #ffffff;

  padding: 0 12px;

  border-radius: 24px 24px 0px 0px;

  position: absolute;
  top: 160px;
  width: 100%;
}

.logo {
  border: 4px solid #ffffff;
  border-radius: 6px;
  position: absolute;
  top: -32px;
  left: 36px;
  height: 64px;
  width: 64px;
  object-fit: contain;
  background: white;
}

.cDetail {
  display: flex;
  justify-content: space-between;
  margin-top: 44px;
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
}

.desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.5);
}
.tile {
  padding: 16px 8px;
  border: 2px solid #eeeeee;
  border-radius: 12px;
  /* display: flex;
    align-items: flex-start;
    justify-content: space-between; */
}

.deliverables {
  margin-top: 90px;
}

.congratsSection {
  display: grid;
  align-items: center;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-top: 20px;
}

.congratsSection p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

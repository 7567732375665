.chip {
  border-radius: 19px;
  padding: 6px 10px;
  text-align: center;
  color: #000000;
}

.green {
  background-color: #99e19c;
}

.orange {
  background-color: #f8c9bf;
}

.blue {
  background-color: #bcd7ff;
}

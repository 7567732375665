.container {
  display: flex;
  gap: 10px;
}

.resImage {
  width: 96px;
  height: 104px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  object-fit: contain;
}

.detailSection {
  display: grid;
  gap: 4px;
}

.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 0;
}

.location {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.44;
  margin-bottom: 0;
}

.description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  margin-bottom: 0;
}

.collabTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #129212;
  margin-bottom: 0;
}

.collabDesc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
  opacity: 0.44;
  margin-bottom: 0;
}

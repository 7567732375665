.container {
  padding: 0 10px;
}

.image {
  width: 100%;
  height: 144px;
}

.divider {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  opacity: 0.5;
  margin-top: 28px;
  margin-bottom: 16px;
}

.tileSectionContainer {
  display: grid;
  gap: 24px;
}

.container {
  width: 100%;
  height: 5em;
  position: fixed;
  bottom: 0;
  padding: 1em 1em;
  display: flex;
  align-items: center;
  margin-top: 1px;
  gap: 7px;
  background-color: #ffffff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  left: 0;
}

.containerDesktop {
  width: 100%;
  height: 5em;
  /* position: fixed; */
  /* bottom: 0; */
  padding: 1em 1em;
  display: flex;
  align-items: center;
  margin-top: 1px;
  gap: 7px;
  background-color: #ffffff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.withdrawContainer {
  display: grid;
  justify-content: center;
  text-align: center;
  margin-top: 41px;
  gap: 3px;
}

.headerHeading {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.balance {
  font-size: 16px;
  line-height: 22px;
}

.amountInput {
  display: flex;
  gap: 8px;
  font-weight: 600;
  font-size: 56px;
  line-height: 78px;
  align-items: center;
  justify-content: center;
}

.accountContainer {
  padding-top: 32px;
  display: grid;
  gap: 17px;
  background: #ffffff;
  box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.13);
  border-radius: 14px;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: grid;
  justify-content: center;
}

.accountHeading {
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #999999;
}

.bankName {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.input {
  font-weight: 600 !important;
  font-size: 44px !important;
  line-height: 62px !important;
  color: #09121f;
  letter-spacing: 0.02em;
}

.alert {
  background: #f0f6ff;
  border-radius: 7px;
  display: flex;
  padding: 12px;
  gap: 8px;
  width: 247px;
  margin: 0 auto;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #1677ff;
}

.container {
  overflow-x: hidden;
  overflow-y: hidden;
}

.header {
  padding: 16px 27px;
  width: 100%;
  height: 5em;
  position: fixed;
  top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 1em;
}

.headerDesktop {
  padding: 16px 27px;
  width: 100%;
  height: 5em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 0;
  display: flex;
  align-items: center;
  gap: 1em;
}

.profile {
  width: 32px;
  height: 32px;
  border-radius: 300px;
  object-fit: cover;
}

.brandName {
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.chats {
  overflow-x: hidden;
  position: fixed;
  height: calc(100vh - 10em);
  width: 100%;
  top: 5em;
  background-color: #f5f5f5;
  overflow-y: scroll;
}

.chatsDesktop {
  overflow-x: hidden;
  height: calc(100vh - 10em - 54px - 10px);
  width: 100%;
  background-color: #f5f5f5;
  overflow-y: scroll;
}

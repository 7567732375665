@import "~antd/dist/antd.less";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

// span {
//   display: block;
// }

p {
  margin: 0 !important;
}

a {
  color: #2130ff;
  text-decoration: underline;
}

:root:root {
  --adm-color-primary: #2130ff;
}

@primary-color: #2130FF;@--z-index: 1300;
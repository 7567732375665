.handleName{
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
}

.sectionHeading{
    margin: 24px 0 16px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
}

@media screen and (max-width: 1000px) {
    .handleName{
        font-size: 24px;
    }

    .sectionHeading{
        margin: 32px 0 4px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
    }

}
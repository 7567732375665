.errorInput {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: red;
}

.titleLabel {
  display: inline-flex;
  gap: 6px;
  align-items: center;
}

.uploadButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 33px 28px;
  gap: 4px;
  background: #ffffff;
  border: 2px dashed rgba(56, 78, 183, 0.3);
  border-radius: 4px;
  width: 344px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #676767;
}

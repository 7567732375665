.subHeading {
  /* color: #999; */
  font-size: 20px;
  font-weight: 400;
}

.socialMediaHeading {
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  padding-top: 5px;
  /* color: #b1b0b0; */
}

.powered {
  /* color: rgba(255, 255, 255, 0.5); */
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 600;
}

.desc {
  /* color: rgba(255, 255, 255, 0.5); */
  font-size: 14px;
  font-weight: 300;
  margin-top: 20px !important;
}

.help {
  color: #533af5;
  font-size: 14px;
  font-weight: 500;
}

.meta {
  display: inline-block;
  width: 24px;
  height: 13px;
}

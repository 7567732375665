.container {
  background: linear-gradient(93.4deg, #050a30 20%, #0044f4 150%);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.13);
  border-radius: 8px;
  position: relative;
  padding: 2em 2em;
  margin: 1.5em;
}
.primaryText {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
}

.secondaryText {
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.actionButton {
  border-bottom: 1px solid #ffffff;
  display: flex;
  gap: 4px;
  border-bottom: 1px solid #ffffff;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  color: #ffffff;
  width: fit-content;
}
.percent {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}

.secondaryActionText {
  /* font-family: "Roboto"; */
  /* font-style: normal; */
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  text-decoration: underline;
  opacity: 0.8;
}

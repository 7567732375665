.container {
  padding: 10px;
}

.swiperContainer {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.swiperItem {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiperImage {
  width: 100%;
  border-radius: 8px;
  height: 212px;
  object-fit: cover;
}

.resDetails {
  margin: 30px 0 35px 0;
  display: grid;
  gap: 12px;
}

.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 0;
}

.location {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.44;
  margin-bottom: 0;
}

.description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  margin-bottom: 0;
}

.collabTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #129212;
  margin-bottom: 6px;
}

.collabDesc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
  opacity: 0.44;
  margin-bottom: 0;
}

.linkContainer {
  display: grid;
  grid-template-columns: 35% 65%;
  align-items: center;
  border: 1px solid #fba364;
  border-radius: 10px;
  padding-left: 13px;
  padding-right: 20px;
  margin: 15px 0;
}

.links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.applyButton {
  padding: 16px 48px;
  background: #1c1b1f;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #fffbfe;
}
